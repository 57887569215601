.root {
    background-image: url(../images/background.jpg?size=2048);
    background-size: contain;
    background-repeat: repeat;
}

.instagram, .facebook {
    color: inherit;
}

.instagram:hover {
    color: #C13584;
}

.facebook:hover {
    color: #3b5998;
}

.root {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.welcome {
    position: relative;
    height: calc(100vh - 64px);
    flex-shrink: 0;
}

.welcome > * {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.welcome > img {
    object-fit: cover;
    object-position: center;
}

.welcome > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.welcome > div[data-overlay] {
    background-color: rgba(0,0,0,0.6);
}

.welcome > div[data-content] > * {
    color: white;
}

.info {
}

.info span {
    text-align: justify;
}

.info img {
    width: 100%;
    margin: 16px 0;
}


.title {
    height: 96px;
    max-width: 100%;
    padding: 0 16px;
}

.subtitle {
    margin-top: 16px;
    letter-spacing: 0.1em;
    font-family: "Caveat", sans-serif;
    font-size: 36px;
    text-align: center;
    line-height: 1.2em;
}

@media (min-width: 992px) {
    .title {
        height: 128px;
    }
    .subtitle {
        font-size: 48px;
    }
}
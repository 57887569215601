@import '~antd/dist/antd.less';

main {
  @media (min-width: 1200px) {
    width: 75%;
    align-self: center;
  }
}

.ant-divider {
  border-color: rgba(0, 0, 0, 0.15)
}

@font-face {
  font-family: 'Caveat';
  src: local('Caveat'), url(fonts/Caveat-VariableFont_wght.ttf) format('truetype');
}

@primary-color: #8c2c30;@link-color: #c85156;@layout-header-background: #141414;@layout-body-background: #ffffff;@layout-footer-background: #141414;@font-size-base: 16px;
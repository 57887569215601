.hoursTable tr > td:first-child {
    text-transform: capitalize;
}

.socialButtons {
    display: flex;
    flex-direction: column;
}

.socialButtons > *:not(:first-child) {
    margin-top: 16px;
}

.instagram:hover {
    border-color: #C13584;
    color: #C13584;
}

.facebook:hover {
    border-color: #3b5998;
    color: #3b5998;
}

.google:hover {
    border-color: #49b567;
    color: #49b567;
}
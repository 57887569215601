.root {
    padding: 16px;
    border: 4px double #777;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.paragraph {
    margin: 0!important;
    padding-left: 16px;
}
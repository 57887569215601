.root {
    position: relative;
    width: 100%;
    cursor: pointer;
    overflow: hidden;
}

.root:after {
    content: "";
    display: block;
}

.rootSquare:after {
    padding-bottom: 100%;
}

.rootRectangle:after {
    padding-bottom: 50%;
}

.img, .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    transition: transform 400ms ease-in-out;
}

.overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.4);
}

.title {
    color: white;
    text-transform: uppercase;
    font-size: 1.5em;
    letter-spacing: 0.1em;
    font-weight: bold;
    text-align: center;
    padding: 8px;
    line-height: 1em;
}

.title:after {
    display:block;
    margin-top: 8px;
    content: '';
    transform: scaleX(0);
    transition: transform 250ms ease-in-out;
    border-bottom: 4px solid white;
}

.root:hover .title:after {
    transform: scaleX(1);
}
.root:hover img {
    transform: scale(1.2);
}


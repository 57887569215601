.root {
    display: flex;
    flex-direction: column;
}

.bottomSpace {
    flex-grow: 1;
}

.row {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 8px;
    margin-top: 8px;
}

.info {
    flex-grow: 1;
    margin-right: 16px;
}

.title {
    margin-bottom: 0!important;
}

.price {
    flex-shrink: 0;
    padding-left: 8px;
}

.divider {
    margin: 0;
}
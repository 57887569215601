.prices {
    width: fit-content;
}

.prices td {
    text-align: right;
    vertical-align: baseline;
}

.prices td:last-child {
    padding-left: 8px;
    white-space: nowrap;
}
.root {
    display: flex;
    flex-direction: row;
}

.bottle {
    flex-shrink: 1;
    height: 200px;
    object-fit: contain;
    margin: 16px 16px 16px 0;
}

.description {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.title {
    display: flex;
}




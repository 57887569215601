.mobileRoot {
    display: grid;
    grid-auto-rows: 1fr;
    grid-template-columns: 1fr 1fr;
    column-gap: 16px;
}

.logo {
    width: 100%;
    height: 125px;
    object-fit: contain;
}

